import React from 'react';
import { string } from 'prop-types';
import ShareIosCard from 'components/cards/share/ios';
import ShareAndroidCard from 'components/cards/share/android';
import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const ShareView = ({ passId }) => {
  const styles = useStyles();

  return (
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={styles.grid}
        >
        <Grid item md={6} sm={6} xs={12}>
          <ShareIosCard passId={passId}/>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <ShareAndroidCard passId={passId}/>
        </Grid>
      </Grid>
      </div>
  );
};

ShareView.propTypes = {
  passId: string.isRequired,
};

export default ShareView;
