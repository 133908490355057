import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { LOYALTY_SHARE_IOS_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const IOSShareCard = ({ passId }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${LOYALTY_SHARE_IOS_ROUTE}?passId=${passId}`)}
          title="Share iOS"
          description="Send loyalty card to an iOS device."
          buttonMessage="Share loyalty card"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faApple} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faApple} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

IOSShareCard.propTypes = {
  passId: string.isRequired,
};

export default IOSShareCard;
